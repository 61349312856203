@font-face {
  font-family: 'Roboto-Regular';
  src: url(/assets/fonts/roboto/Roboto-Regular-webfont.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url(/assets/fonts/roboto/Roboto-Bold-webfont.ttf) format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto-Thin';
  src: url(/assets/fonts/roboto/Roboto-Thin-webfont.ttf) format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto-Black';
  src: url(/assets/fonts/roboto/Roboto-Black-webfont.ttf) format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Roboto-Light';
  src: url(/assets/fonts/roboto/Roboto-Light-webfont.ttf) format('truetype');
  font-weight: lighter;
}
@font-face {
  font-family: 'Cookie';
  src: url(/assets/fonts/cookie/Cookie-Regular.ttf) format('truetype');
  font-weight: 300;
}
.f_1stFont {
  font-family: 'Roboto-Regular', sans-serif;
  font-weight: 300;
}
.f_1stFontItalic {
  font-family: 'Roboto-Regular', 'sans-serif';
  font-style: italic;
  font-weight: 300;
}
.f_1stFontItalic {
  font-family: 'Roboto-Regular', sans-serif;
  font-style: italic;
  font-weight: 300;
}
.f_1stFontLight {
  font-family: 'Roboto-Light', sans-serif;
  font-weight: lighter;
}
.f_1stFontLightItalic {
  font-family: 'Roboto-Light', sans-serif;
  font-weight: lighter;
  font-style: italic;
}
.f_1stFontBold {
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
}
.f_1stFontSemiBold {
  font-family: 'Roboto-Black';
  font-weight: 700;
}
.f_1stFontBoldItalic {
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
  font-style: italic;
}
.f_1stFontThin {
  font-family: 'Roboto-Thin';
  font-weight: 100;
}
.f_2ndFont {
  font-family: 'Cookie';
  font-weight: 300;
}
.f_2ndFontBold {
  font-family: 'Cookie';
  font-weight: bolder;
}
.f_2ndFontThin {
  font-family: 'Roboto-Thin';
  font-weight: 100;
}
.animation-slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(8%);
  }
  65% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}
@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }
  50% {
    -webkit-transform: translateY(8%);
  }
  65% {
    -webkit-transform: translateY(-4%);
  }
  80% {
    -webkit-transform: translateY(4%);
  }
  95% {
    -webkit-transform: translateY(-2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
/*
==============================================
slideUp
==============================================
*/
.animation-slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}
@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-8%);
  }
  65% {
    transform: translateY(4%);
  }
  80% {
    transform: translateY(-4%);
  }
  95% {
    transform: translateY(2%);
  }
  100% {
    transform: translateY(0%);
  }
}
@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }
  50% {
    -webkit-transform: translateY(-8%);
  }
  65% {
    -webkit-transform: translateY(4%);
  }
  80% {
    -webkit-transform: translateY(-4%);
  }
  95% {
    -webkit-transform: translateY(2%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
/*
==============================================
slideLeft
==============================================
*/
.animation-slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}
@keyframes slideLeft {
  0% {
    transform: translateX(150%);
  }
  50% {
    transform: translateX(-8%);
  }
  65% {
    transform: translateX(4%);
  }
  80% {
    transform: translateX(-4%);
  }
  95% {
    transform: translateX(2%);
  }
  100% {
    transform: translateX(0%);
  }
}
@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
  }
  50% {
    -webkit-transform: translateX(-8%);
  }
  65% {
    -webkit-transform: translateX(4%);
  }
  80% {
    -webkit-transform: translateX(-4%);
  }
  95% {
    -webkit-transform: translateX(2%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}
/*
==============================================
slideRight
==============================================
*/
.animation-slideRight {
  animation-name: slideRight;
  -webkit-animation-name: slideRight;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}
@keyframes slideRight {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(8%);
  }
  65% {
    transform: translateX(-4%);
  }
  80% {
    transform: translateX(4%);
  }
  95% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0%);
  }
}
@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(-150%);
  }
  50% {
    -webkit-transform: translateX(8%);
  }
  65% {
    -webkit-transform: translateX(-4%);
  }
  80% {
    -webkit-transform: translateX(4%);
  }
  95% {
    -webkit-transform: translateX(-2%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}
/*
==============================================
slideExpandUp
==============================================
*/
.animation-slideExpandUp {
  animation-name: slideExpandUp;
  -webkit-animation-name: slideExpandUp;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease -out;
  visibility: visible !important;
}
@keyframes slideExpandUp {
  0% {
    transform: translateY(100%) scaleX(0.5);
  }
  30% {
    transform: translateY(-8%) scaleX(0.5);
  }
  40% {
    transform: translateY(2%) scaleX(0.5);
  }
  50% {
    transform: translateY(0%) scaleX(1.1);
  }
  60% {
    transform: translateY(0%) scaleX(0.9);
  }
  70% {
    transform: translateY(0%) scaleX(1.05);
  }
  80% {
    transform: translateY(0%) scaleX(0.95);
  }
  90% {
    transform: translateY(0%) scaleX(1.02);
  }
  100% {
    transform: translateY(0%) scaleX(1);
  }
}
@-webkit-keyframes slideExpandUp {
  0% {
    -webkit-transform: translateY(100%) scaleX(0.5);
  }
  30% {
    -webkit-transform: translateY(-8%) scaleX(0.5);
  }
  40% {
    -webkit-transform: translateY(2%) scaleX(0.5);
  }
  50% {
    -webkit-transform: translateY(0%) scaleX(1.1);
  }
  60% {
    -webkit-transform: translateY(0%) scaleX(0.9);
  }
  70% {
    -webkit-transform: translateY(0%) scaleX(1.05);
  }
  80% {
    -webkit-transform: translateY(0%) scaleX(0.95);
  }
  90% {
    -webkit-transform: translateY(0%) scaleX(1.02);
  }
  100% {
    -webkit-transform: translateY(0%) scaleX(1);
  }
}
/*
==============================================
expandUp
==============================================
*/
.animation-expandUp {
  animation-name: expandUp;
  -webkit-animation-name: expandUp;
  animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}
@keyframes expandUp {
  0% {
    transform: translateY(100%) scale(0.6) scaleY(0.5);
  }
  60% {
    transform: translateY(-7%) scaleY(1.12);
  }
  75% {
    transform: translateY(3%);
  }
  100% {
    transform: translateY(0%) scale(1) scaleY(1);
  }
}
@-webkit-keyframes expandUp {
  0% {
    -webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
  }
  60% {
    -webkit-transform: translateY(-7%) scaleY(1.12);
  }
  75% {
    -webkit-transform: translateY(3%);
  }
  100% {
    -webkit-transform: translateY(0%) scale(1) scaleY(1);
  }
}
/*
==============================================
fadeOut
==============================================
*/
.animation-fadeOut {
  animation-name: fadeOut;
  -webkit-animation-name: fadeOut;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0 !important;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0 !important;
  }
}
/*
==============================================
fadeIn
==============================================
*/
.animation-fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1 !important;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1 !important;
  }
}
/*
==============================================
expandOpen
==============================================
*/
.animation-expandOpen {
  animation-name: expandOpen;
  -webkit-animation-name: expandOpen;
  animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  visibility: visible !important;
}
@keyframes expandOpen {
  0% {
    transform: scale(1.8);
  }
  50% {
    transform: scale(0.95);
  }
  80% {
    transform: scale(1.05);
  }
  90% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes expandOpen {
  0% {
    -webkit-transform: scale(1.8);
  }
  50% {
    -webkit-transform: scale(0.95);
  }
  80% {
    -webkit-transform: scale(1.05);
  }
  90% {
    -webkit-transform: scale(0.98);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
/*
==============================================
bigEntrance
==============================================
*/
.animation-bigEntrance {
  animation-name: bigEntrance;
  -webkit-animation-name: bigEntrance;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  visibility: visible !important;
}
@keyframes bigEntrance {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}
@-webkit-keyframes bigEntrance {
  0% {
    -webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }
  30% {
    -webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  60% {
    -webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}
/*
==============================================
hatch
==============================================
*/
.animation-hatch {
  animation-name: hatch;
  -webkit-animation-name: hatch;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  visibility: visible !important;
}
@keyframes hatch {
  0% {
    transform: rotate(0deg) scaleY(0.6);
  }
  20% {
    transform: rotate(-2deg) scaleY(1.05);
  }
  35% {
    transform: rotate(2deg) scaleY(1);
  }
  50% {
    transform: rotate(-2deg);
  }
  65% {
    transform: rotate(1deg);
  }
  80% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes hatch {
  0% {
    -webkit-transform: rotate(0deg) scaleY(0.6);
  }
  20% {
    -webkit-transform: rotate(-2deg) scaleY(1.05);
  }
  35% {
    -webkit-transform: rotate(2deg) scaleY(1);
  }
  50% {
    -webkit-transform: rotate(-2deg);
  }
  65% {
    -webkit-transform: rotate(1deg);
  }
  80% {
    -webkit-transform: rotate(-1deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
/*
==============================================
bounce
==============================================
*/
.animation-bounce {
  animation-name: bounce;
  -webkit-animation-name: bounce;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  transform-origin: 80% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
}
@keyframes bounce {
  0% {
    transform: translateY(0%) scaleY(0.6);
  }
  60% {
    transform: translateY(-30%) scaleY(1.1);
  }
  70% {
    transform: translateY(0%) scaleY(0.95) scaleX(1.05);
  }
  80% {
    transform: translateY(0%) scaleY(1.05) scaleX(1);
  }
  90% {
    transform: translateY(0%) scaleY(0.95) scaleX(1);
  }
  100% {
    transform: translateY(0%) scaleY(1) scaleX(1);
  }
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0%) scaleY(0.6);
  }
  60% {
    -webkit-transform: translateY(-30%) scaleY(1.1);
  }
  70% {
    -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
  }
  80% {
    -webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
  }
  90% {
    -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
  }
  100% {
    -webkit-transform: translateY(0%) scaleY(1) scaleX(1);
  }
}
/*
==============================================
pulse
==============================================
*/
.animation-pulse {
  animation-name: pulse;
  -webkit-animation-name: pulse;
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.03);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
/*
==============================================
Scale
==============================================
*/
.animation-scale {
  animation: ease-out normal backwards;
  -webkit-animation: ease-out normal backwards;
  animation-name: scale-box;
  -webkit-animation-name: scale-box;
  animation-duration: 250ms;
  -webkit-animation-duration: 250ms;
  animation-iteration-count: normal;
  -webkit-animation-iteration-count: normal ;
  animation-delay: 0.55s;
  -webkit-animation-delay: 0.55s;
}
@keyframes scale-box {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  85% {
    transform: scale(1.08);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes scale-box {
  0% {
    -webkit-transform: scale(0);
  }
  85% {
    -webkit-transform: scale(1.08);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
/*
==============================================
floating
==============================================
*/
@keyframes floating {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(8%);
  }
  100% {
    transform: translateY(0%);
  }
}
@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0%);
  }
  50% {
    -webkit-transform: translateY(8%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
/*
==============================================
tossing
==============================================
*/
@keyframes tossing {
  0% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(-1deg);
  }
}
@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-1deg);
  }
  50% {
    -webkit-transform: rotate(1deg);
  }
  100% {
    -webkit-transform: rotate(-1deg);
  }
}
/*
==============================================
pullUp
==============================================
*/
.animation-pullUp {
  animation-name: pullUp;
  -webkit-animation-name: pullUp;
  animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
}
@keyframes pullUp {
  0% {
    transform: scaleY(0.1);
  }
  40% {
    transform: scaleY(1.02);
  }
  60% {
    transform: scaleY(0.98);
  }
  80% {
    transform: scaleY(1.01);
  }
  100% {
    transform: scaleY(0.98);
  }
  80% {
    transform: scaleY(1.01);
  }
  100% {
    transform: scaleY(1);
  }
}
@-webkit-keyframes pullUp {
  0% {
    -webkit-transform: scaleY(0.1);
  }
  40% {
    -webkit-transform: scaleY(1.02);
  }
  60% {
    -webkit-transform: scaleY(0.98);
  }
  80% {
    -webkit-transform: scaleY(1.01);
  }
  100% {
    -webkit-transform: scaleY(0.98);
  }
  80% {
    -webkit-transform: scaleY(1.01);
  }
  100% {
    -webkit-transform: scaleY(1);
  }
}
/*
==============================================
pullDown
==============================================
*/
.animation-pullDown {
  animation-name: pullDown;
  -webkit-animation-name: pullDown;
  animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
}
@keyframes pullDown {
  0% {
    transform: scaleY(0.1);
  }
  40% {
    transform: scaleY(1.02);
  }
  60% {
    transform: scaleY(0.98);
  }
  80% {
    transform: scaleY(1.01);
  }
  100% {
    transform: scaleY(0.98);
  }
  80% {
    transform: scaleY(1.01);
  }
  100% {
    transform: scaleY(1);
  }
}
@-webkit-keyframes pullDown {
  0% {
    -webkit-transform: scaleY(0.1);
  }
  40% {
    -webkit-transform: scaleY(1.02);
  }
  60% {
    -webkit-transform: scaleY(0.98);
  }
  80% {
    -webkit-transform: scaleY(1.01);
  }
  100% {
    -webkit-transform: scaleY(0.98);
  }
  80% {
    -webkit-transform: scaleY(1.01);
  }
  100% {
    -webkit-transform: scaleY(1);
  }
}
/*
==============================================
stretchLeft
==============================================
*/
.animation-stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%;
}
@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3);
  }
  40% {
    transform: scaleX(1.02);
  }
  60% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(1);
  }
}
@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
  }
  40% {
    -webkit-transform: scaleX(1.02);
  }
  60% {
    -webkit-transform: scaleX(0.98);
  }
  80% {
    -webkit-transform: scaleX(1.01);
  }
  100% {
    -webkit-transform: scaleX(0.98);
  }
  80% {
    -webkit-transform: scaleX(1.01);
  }
  100% {
    -webkit-transform: scaleX(1);
  }
}
/*
==============================================
stretchRight
==============================================
*/
.animation-stretchRight {
  animation-name: stretchRight;
  -webkit-animation-name: stretchRight;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
}
@keyframes stretchRight {
  0% {
    transform: scaleX(0.3);
  }
  40% {
    transform: scaleX(1.02);
  }
  60% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(0.98);
  }
  80% {
    transform: scaleX(1.01);
  }
  100% {
    transform: scaleX(1);
  }
}
@-webkit-keyframes stretchRight {
  0% {
    -webkit-transform: scaleX(0.3);
  }
  40% {
    -webkit-transform: scaleX(1.02);
  }
  60% {
    -webkit-transform: scaleX(0.98);
  }
  80% {
    -webkit-transform: scaleX(1.01);
  }
  100% {
    -webkit-transform: scaleX(0.98);
  }
  80% {
    -webkit-transform: scaleX(1.01);
  }
  100% {
    -webkit-transform: scaleX(1);
  }
}
/*
==============================================
heartbeat
==============================================
*/
.animation-heartbeat {
  -webkit-animation: heartbeat 1800ms ease 0s infinite normal;
  -khtml-animation: heartbeat 1800ms ease 0s infinite normal;
  -moz-animation: heartbeat 1800ms ease 0s infinite normal;
  -ms-animation: heartbeat 1800ms ease 0s infinite normal;
  -o-animation: heartbeat 1800ms ease 0s infinite normal;
  animation: heartbeat 1800ms ease 0s infinite normal;
}
@-webkit-keyframes heartbeat {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  28% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes heartbeat {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  28% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes heartbeat {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  28% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes heartbeat {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  28% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes heartbeat {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  28% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes heartbeat {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  28% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes heartbeat {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  28% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes heartbeat {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  28% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.animation-rotation {
  -webkit-animation: rotation 60s linear infinite normal;
  -khtml-animation: rotation 60s linear infinite normal;
  -moz-animation: rotation 60s linear infinite normal;
  -ms-animation: rotation 60s linear infinite normal;
  -o-animation: rotation 60s linear infinite normal;
  animation: rotation 60s linear infinite normal;
}
@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  50% {
    -webkit-transform: rotate(180deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -moz-transform: rotate(180deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -o-transform: rotate(180deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -ms-transform: rotate(180deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    transform: rotate(180deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -moz-transform: rotate(360deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -o-transform: rotate(360deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -ms-transform: rotate(360deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    transform: rotate(360deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
.preloader-animation {
  animation: 1s ease infinite normal;
}
@keyframes preloader_dot_1 {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: #fff;
  }
  40% {
    background-color: transparent;
  }
  60% {
    background-color: transparent;
  }
  80% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes preloader_dot_2 {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: transparent;
  }
  40% {
    background-color: #fff;
  }
  60% {
    background-color: transparent;
  }
  80% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes preloader_dot_3 {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: transparent;
  }
  40% {
    background-color: transparent;
  }
  60% {
    background-color: #fff;
  }
  80% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes preloader_dot_4 {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: transparent;
  }
  40% {
    background-color: transparent;
  }
  60% {
    background-color: transparent;
  }
  80% {
    background-color: #fff;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes preloader_dot_5 {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: transparent;
  }
  40% {
    background-color: transparent;
  }
  60% {
    background-color: transparent;
  }
  80% {
    background-color: transparent;
  }
  100% {
    background-color: #fff;
  }
}
/*
==============================================
Animate New Order-Item
==============================================
*/
.animation-newOrderItem {
  -webkit-animation: pulseborder 1800ms ease 0s infinite normal;
  -khtml-animation: pulseborder 1800ms ease 0s infinite normal;
  -moz-animation: pulseborder 1800ms ease 0s infinite normal;
  -ms-animation: pulseborder 1800ms ease 0s infinite normal;
  -o-animation: pulseborder 1800ms ease 0s infinite normal;
  animation: pulseborder 1800ms ease 0s infinite normal;
}
@keyframes pulseborder {
  0% {
    border: 5px solid transparent;
  }
  50% {
    border: 5px solid #78ac28;
  }
  100% {
    border: 5px solid transparent;
  }
}
.pReset {
  margin-bottom: 0;
  line-height: 0;
  font-size: 0;
}
.killFlex {
  display: inline-block !important;
  flex-direction: initial !important;
  -webkit-flex-direction: initial;
  -webkit-box-orient: initial;
  align-items: initial !important;
  -webkit-align-items: initial;
  -webkit-box-align: initial;
  align-content: initial !important;
  -webkit-align-content: initial;
  justify-content: initial !important;
  -webkit-justify-content: initial;
  -webkit-box-pack: initial;
  flex-wrap: initial !important;
  -webkit-flex-wrap: initial;
  -webkit-box-lines: initial;
}
.main-wrapper {
  display: block;
  padding: 20px;
  margin: 0;
}
.fullscreen {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}
.scrollbar1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background-color: transparent;
}
.scrollbar1::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.scrollbar1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #058da9;
}
.scrollbar2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background-color: #058da9;
}
.scrollbar2::-webkit-scrollbar {
  width: 6px;
  background-color: #058da9;
}
.scrollbar2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.no-scrollbar {
  /* 1 */
  /* 2 */
  /* 3 */
  /* 4 */
  /* 5 */
  /* 6 */
  /* 7 */
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar::-webkit-scrollbar-button {
  display: none;
}
.no-scrollbar::-webkit-scrollbar-track {
  display: none;
}
.no-scrollbar::-webkit-scrollbar-track-piece {
  display: none;
}
.no-scrollbar::-webkit-scrollbar-thumb {
  display: none;
}
.no-scrollbar::-webkit-scrollbar-corner {
  display: none;
}
.no-scrollbar::-webkit-resizer {
  display: none;
}
.text {
  color: #058da9;
  font-size: 18px;
  line-height: 100%;
}
.link,
.link:before {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  color: #058da9;
}
.link:hover,
.link:hover:before,
.link.active,
.link.active-trail,
.link.active:before,
.link.active-trail:before {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  color: #026f85;
}
.strong {
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
}
.bold {
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
}
.semiBold {
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
}
.normal {
  font-family: 'Roboto-Light', sans-serif;
  font-weight: lighter;
}
.italic {
  font-style: italic;
}
.em {
  font-style: italic;
}
.p {
  color: #058da9;
  font-size: 18px;
  line-height: 100%;
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
  margin: 0;
  margin-bottom: 14px;
  color: #363636;
}
.h1 {
  margin: 0;
  line-height: 120%;
  color: #058da9;
  font-size: 48px;
  position: relative;
  text-transform: none;
  font-family: 'Roboto-Thin';
  font-weight: 100;
}
.h2 {
  font-family: 'Roboto-Regular', sans-serif;
  font-weight: 300;
  margin: 0;
  line-height: 130%;
  color: #058da9;
  font-size: 32px;
}
.h3 {
  font-family: 'Cookie';
  font-weight: 300;
  margin: 0;
  line-height: 130%;
  color: #058da9;
  font-size: 45px;
}
.h4 {
  font-family: 'Roboto-Regular', sans-serif;
  font-weight: 300;
  margin: 0;
  line-height: 140%;
  color: #363636;
  font-size: 17px;
}
.h5 {
  font-family: 'Cookie';
  font-weight: 300;
  margin: 0;
  line-height: 130%;
  color: #026f85;
  font-size: 14px;
}
.h6 {
  font-family: 'Cookie';
  font-weight: 300;
  margin: 0;
  line-height: 130%;
  color: #026f85;
  font-size: 11px;
}
.hr {
  height: 0;
  display: block;
  margin-top: 20px;
  margin-bottom: 40px;
  border: 0;
  border-bottom: 1px solid #82BB51;
}
.selection {
  background-color: #026f85;
  color: #fff;
}
.listNormal {
  list-style-type: none;
  margin-bottom: 40px;
  margin-top: 10px;
}
.listNormal li {
  padding-left: 14px;
  line-height: 140%;
  margin: 0;
  margin-bottom: 6px;
  font-size: 18px;
  position: relative;
}
.listNormal li p,
.listNormal li h1,
.listNormal li h2,
.listNormal li h3,
.listNormal li h4,
.listNormal li h5 {
  margin-bottom: 0;
}
.listNormal li:before {
  content: "•";
  color: #026f85;
  font-size: 10px;
  left: 0;
  position: absolute;
  vertical-align: middle;
}
.listNumber {
  list-style-type: decimal;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: -6px;
}
.listNumber li {
  padding-left: 10px;
  font-family: 'Roboto-Regular', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 160%;
  margin-bottom: 6px;
}
.resetList {
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 0;
}
.resetList li {
  padding-left: 0;
  font-family: 'Roboto-Regular', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 160%;
  margin-bottom: 0;
}
.resetList li:before {
  content: " ";
}
h1 {
  margin: 0;
  line-height: 120%;
  color: #058da9;
  font-size: 48px;
  position: relative;
  text-transform: none;
  font-family: 'Roboto-Thin';
  font-weight: 100;
}
h2 {
  font-family: 'Roboto-Regular', sans-serif;
  font-weight: 300;
  margin: 0;
  line-height: 130%;
  color: #058da9;
  font-size: 32px;
}
h3 {
  font-family: 'Cookie';
  font-weight: 300;
  margin: 0;
  line-height: 130%;
  color: #058da9;
  font-size: 45px;
}
h4 {
  font-family: 'Roboto-Regular', sans-serif;
  font-weight: 300;
  margin: 0;
  line-height: 140%;
  color: #363636;
  font-size: 17px;
}
h5 {
  font-family: 'Cookie';
  font-weight: 300;
  margin: 0;
  line-height: 130%;
  color: #026f85;
  font-size: 14px;
}
h6 {
  font-family: 'Cookie';
  font-weight: 300;
  margin: 0;
  line-height: 130%;
  color: #026f85;
  font-size: 11px;
}
p {
  color: #058da9;
  font-size: 18px;
  line-height: 100%;
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
  margin: 0;
  margin-bottom: 14px;
  color: #363636;
}
.highlighted {
  color: #058da9;
  padding: 3.33333333px 5px;
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  background-image: -moz-linear-gradient(135deg, #ffffff 0%, #dcdcc8 100%);
  background-image: -webkit-linear-gradient(135deg, #ffffff 0%, #dcdcc8 100%);
  background-image: -ms-linear-gradient(135deg, #ffffff 0%, #dcdcc8 100%);
  background-image: linear-gradient(135deg, #ffffff 0%, #dcdcdc 100%);
}
.highlighted a,
.highlighted a:before,
.highlighted a:after {
  color: #026f85 !important;
}
.button1,
.button1 a {
  display: inline-block;
  position: relative;
  z-index: 3;
  color: #82BB51;
  background-color: #000;
  font-size: 18px;
  padding: 10px 20px;
  border: 1px solid #82BB51;
  border-radius: 10px;
  line-height: 100%;
  cursor: pointer;
  font-family: 'Roboto-Black';
  font-weight: 700;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.button1:hover,
.button1 a:hover {
  color: #fff;
  border: 1px solid #fff;
  -webkit-box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
  -moz-box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
  box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
}
.button1.big,
.button1.big a {
  font-size: 20px;
  padding: 7.14285714px 10px;
}
.button-nav-1 {
  width: 35px;
  height: 35px;
  display: block;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #000;
}
.button2,
.button2 a {
  display: inline-block;
  position: relative;
  z-index: 3;
  color: #fff;
  font-family: 'Roboto-Black';
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px 10px;
  line-height: 100%;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.button2 .icon,
.button2 a .icon {
  font-size: 16px;
  margin-left: 10px / 3;
}
.button2 .icon:before,
.button2 a .icon:before {
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0) translateY(0px);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0) translateY(0px);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0) translateY(0px);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0) translateY(0px);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0) translateY(0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.button2:before,
.button2 a:before,
.button2:after,
.button2 a:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(0deg) scale(1) skewX(-11deg) skewX(-11deg) translateX(-50%) translateY(-50%);
  -moz-transform: rotate(0deg) scale(1) skewX(-11deg) skewX(-11deg) translateX(-50%) translateY(-50%);
  -o-transform: rotate(0deg) scale(1) skewX(-11deg) skewX(-11deg) translateX(-50%) translateY(-50%);
  -ms-transform: rotate(0deg) scale(1) skewX(-11deg) skewX(-11deg) translateX(-50%) translateY(-50%);
  transform: rotate(0deg) scale(1) skewX(-11deg) skewX(-11deg) translateX(-50%) translateY(-50%);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -ms-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.button2:before,
.button2 a:before {
  z-index: -2;
  opacity: 1;
  background-image: -moz-linear-gradient(0deg, #026f85 0%, #058da9 100%);
  background-image: -webkit-linear-gradient(0deg, #026f85 0%, #058da9 100%);
  background-image: -ms-linear-gradient(0deg, #026f85 0%, #058da9 100%);
  background-image: linear-gradient(0deg, #026f85 0%, #058da9 100%);
  -webkit-box-shadow: 0 3px 2px rgba(21, 24, 46, 0.2);
  -moz-box-shadow: 0 3px 2px rgba(21, 24, 46, 0.2);
  box-shadow: 0 3px 2px rgba(21, 24, 46, 0.2);
}
.button2:after,
.button2 a:after {
  opacity: 0;
  z-index: -1;
  background-image: -moz-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: -webkit-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: -ms-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: linear-gradient(0deg, #111111 0%, #232323 100%);
}
.button2:hover,
.button2 a:hover,
.button2:hover .icon,
.button2 a:hover .icon {
  color: #fff;
}
.button2:hover .icon:before,
.button2 a:hover .icon:before {
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(3px) translateY(0px);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(3px) translateY(0px);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(3px) translateY(0px);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(3px) translateY(0px);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(3px) translateY(0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.button2:hover:after,
.button2 a:hover:after {
  opacity: 1;
  -webkit-transition: opacity 250ms ease-in-out;
  -moz-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
}
.button2:hover:before,
.button2 a:hover:before {
  -webkit-transition: opacity 250ms ease-in-out;
  -moz-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
}
.button2.big,
.button2.big a {
  font-size: 20px;
  padding: 7.14285714px 10px;
}
.button3,
.button3 a {
  display: inline-block;
  position: relative;
  z-index: 3;
  color: #058da9;
  font-family: 'Roboto-Light', sans-serif;
  font-weight: lighter;
  font-size: 15px;
  padding: 0 5px;
  padding-left: 14px;
  line-height: 120%;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.button3:before,
.button3 a:before,
.button3:after,
.button3 a:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 250ms ease-in-out;
  -moz-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
}
.button3:before,
.button3 a:before {
  opacity: 1;
  z-index: -2;
}
.button3:after,
.button3 a:after {
  opacity: 0;
  z-index: -1;
}
.button3 .icon:before,
.button3 a .icon:before {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  width: 1em;
  height: 1em;
  font-size: 1em;
}
.button3:hover,
.button3 a:hover {
  color: #82BB51;
}
.button3:hover:after,
.button3 a:hover:after {
  opacity: 1;
  -webkit-transition: opacity 250ms ease-in-out;
  -moz-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
}
.button3:hover:before,
.button3 a:hover:before {
  opacity: 0;
  -webkit-transition: opacity 250ms ease-in-out;
  -moz-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
}
.button3:hover .icon:before,
.button3 a:hover .icon:before {
  margin-left: 2px;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.button3.big,
.button3.big a {
  font-size: 19px;
}
.shadow1 {
  -webkit-box-shadow: 0px 0px 25px 25px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 25px 25px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 25px 25px rgba(0, 0, 0, 0.08);
}
.shadow2 {
  -webkit-box-shadow: 0 3px 2px rgba(21, 24, 46, 0.2);
  -moz-box-shadow: 0 3px 2px rgba(21, 24, 46, 0.2);
  box-shadow: 0 3px 2px rgba(21, 24, 46, 0.2);
}
.shadow3 {
  -webkit-box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
  -moz-box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
  box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
}
.shadow4 {
  -webkit-box-shadow: 0px 0px 175px 0px rgba(112, 80, 28, 0.5);
  -moz-box-shadow: 0px 0px 175px 0px rgba(112, 80, 28, 0.5);
  box-shadow: 0px 0px 175px 0px rgba(112, 80, 28, 0.5);
}
.shadow5 {
  -webkit-box-shadow: 17px 18px 32px -12px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 17px 18px 32px -12px rgba(0, 0, 0, 0.8);
  box-shadow: 17px 18px 32px -12px rgba(0, 0, 0, 0.8);
}
.text-outline {
  -webkit-text-shadown: -2px -2px 0 #BB5151, 2px -2px 0 #BB5151, -2px 2px 0 #BB5151, 2px 2px 0 #BB5151;
  -moz-text-shadown: -2px -2px 0 #BB5151, 2px -2px 0 #BB5151, -2px 2px 0 #BB5151, 2px 2px 0 #BB5151;
  text-shadow: -2px -2px 0 #BB5151, 2px -2px 0 #BB5151, -2px 2px 0 #BB5151, 2px 2px 0 #BB5151;
}
@media screen and (max-width: 1367px) {
  .text-outline {
    -webkit-text-shadown: -1px -1px 0 #BB5151, 1px -1px 0 #BB5151, -1px 1px 0 #BB5151, 1px 1px 0 #BB5151;
    -moz-text-shadown: -1px -1px 0 #BB5151, 1px -1px 0 #BB5151, -1px 1px 0 #BB5151, 1px 1px 0 #BB5151;
    text-shadow: -1px -1px 0 #BB5151, 1px -1px 0 #BB5151, -1px 1px 0 #BB5151, 1px 1px 0 #BB5151;
  }
}
.slickSlideshow .slick .block-content {
  position: relative;
  display: box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.slickSlideshow .slick .block-content .views-field-edit-node-1 a {
  position: absolute;
  z-index: 2500;
  top: -20px;
  left: 20px;
  color: #058da9;
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  opacity: 0;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  font-size: 14px;
}
.slickSlideshow .slick .block-content .field-name-field-header-link a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: #058da9;
  opacity: 0;
}
.slickSlideshow .slick .block-content .content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 2;
  display: box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify ;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-width: 100%;
  max-width: 100%;
}
.slickSlideshow .slick .block-content:hover .views-field-edit-node-1 a {
  top: 20px;
  opacity: 1;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickSlideshow .slick .block-content .backgroundImage {
  width: 100%;
  left: 0;
  position: relative;
  z-index: 1;
}
.slickSlideshow .slick .block-content .backgroundImage img {
  min-width: 100%;
  width: 100% !important;
  height: auto !important;
}
.slickSlideshow .slick__arrow {
  width: 100% !important;
  top: 50%;
  left: 50%;
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.slickSlideshow .slick__arrow a.slick-arrow {
  width: 126px;
  height: 126px;
  border: 2px solid #fff;
  overflow: hidden;
  opacity: 0.9;
  display: block;
  cursor: pointer;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(-50%);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(-50%);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(-50%);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(-50%);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(-50%);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media all and (max-width: 1500px) {
  .slickSlideshow .slick__arrow a.slick-arrow {
    width: 80px;
    height: 80px;
  }
}
@media all and (max-width: 1260px) {
  .slickSlideshow .slick__arrow a.slick-arrow {
    width: 60px;
    height: 60px;
  }
}
.slickSlideshow .slick__arrow a.slick-arrow:before {
  text-align: center;
  color: #fff;
  font-size: 50px;
  width: 1em;
  height: 1em;
  line-height: 1em;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 2;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickSlideshow .slick__arrow a.slick-arrow:hover {
  opacity: 1;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickSlideshow .slick__arrow a.slick-arrow:hover:before {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickSlideshow .slick__arrow a.slick-arrow.slick-prev {
  left: 14%;
  background-position: bottom left;
}
.slickSlideshow .slick__arrow a.slick-arrow.slick-prev:before {
  margin-left: 0;
}
.slickSlideshow .slick__arrow a.slick-arrow.slick-prev:hover:before {
  margin-left: -4px;
}
@media all and (max-width: 1850px) {
  .slickSlideshow .slick__arrow a.slick-arrow.slick-prev {
    left: 2%;
  }
}
.slickSlideshow .slick__arrow a.slick-arrow.slick-next {
  right: 14%;
  background-position: top left;
}
.slickSlideshow .slick__arrow a.slick-arrow.slick-next:before {
  margin-right: 0;
}
.slickSlideshow .slick__arrow a.slick-arrow.slick-next:hover:before {
  margin-left: 4px;
}
@media all and (max-width: 1850px) {
  .slickSlideshow .slick__arrow a.slick-arrow.slick-next {
    right: 2%;
  }
}
.slickSlideshow:hover .slick__arrow a.slick-arrow {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickCarousel {
  position: relative;
  z-index: 4;
}
.slickCarousel .slick .slide__content {
  z-index: 2;
  position: relative;
}
.slickCarousel .slick .slide__content .content {
  padding: 10px 60px;
  position: relative;
}
.slickCarousel .slick .slide__content .content .views-field-edit-node {
  position: absolute;
  bottom: 10px / 2;
  right: 10px / 2;
  margin-bottom: -60px;
  margin-right: -60px;
}
.slickCarousel .slick .slide__content .content .views-field-edit-node a {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  opacity: 0;
}
.slickCarousel .slick .slide__content .content .views-field-edit-node a,
.slickCarousel .slick .slide__content .content .views-field-edit-node a a {
  display: inline-block;
  position: relative;
  z-index: 3;
  color: #82BB51;
  background-color: #000;
  font-size: 18px;
  padding: 10px 20px;
  border: 1px solid #82BB51;
  border-radius: 10px;
  line-height: 100%;
  cursor: pointer;
  font-family: 'Roboto-Black';
  font-weight: 700;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickCarousel .slick .slide__content .content .views-field-edit-node a:hover,
.slickCarousel .slick .slide__content .content .views-field-edit-node a a:hover {
  color: #fff;
  border: 1px solid #fff;
  -webkit-box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
  -moz-box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
  box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
}
.slickCarousel .slick .slide__content .content .views-field-edit-node a.big,
.slickCarousel .slick .slide__content .content .views-field-edit-node a.big a {
  font-size: 20px;
  padding: 7.14285714px 10px;
}
.slickCarousel .slick .slide__content .content:hover .views-field-edit-node {
  margin-bottom: 0;
  margin-right: 0;
}
.slickCarousel .slick .slide__content .content:hover .views-field-edit-node a {
  opacity: 1;
}
.slickCarousel .slick__arrow {
  width: 100% !important;
  top: 50%;
  left: 50%;
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.slickCarousel .slick__arrow a.slick-arrow {
  width: 40px;
  height: 40px;
  color: rgba(5, 141, 169, 0.5);
  overflow: hidden;
  opacity: 0.9;
  display: block;
  cursor: pointer;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(-50%);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(-50%);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(-50%);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(-50%);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(-50%);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
.slickCarousel .slick__arrow a.slick-arrow:before {
  text-align: center;
  color: rgba(5, 141, 169, 0.5) !important;
  font-size: 60px;
  width: 1em;
  height: 1em;
  line-height: 1em;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 2;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickCarousel .slick__arrow a.slick-arrow:hover {
  opacity: 1;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickCarousel .slick__arrow a.slick-arrow:hover:before {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickCarousel .slick__arrow a.slick-arrow.slick-prev {
  left: 0;
  background-position: bottom left;
}
.slickCarousel .slick__arrow a.slick-arrow.slick-prev:before {
  margin-left: 0;
}
.slickCarousel .slick__arrow a.slick-arrow.slick-prev:hover:before {
  margin-left: -4px;
}
.slickCarousel .slick__arrow a.slick-arrow.slick-next {
  right: 0;
  background-position: top left;
}
.slickCarousel .slick__arrow a.slick-arrow.slick-next:before {
  margin-right: 0;
}
.slickCarousel .slick__arrow a.slick-arrow.slick-next:hover:before {
  margin-left: 4px;
}
.slickCarousel .slick-dots li {
  font-size: 0 !important;
  display: inline-block;
  margin: 10px / 6 !important;
  padding: 0 !important;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  position: relative;
  z-index: 3;
  width: 10px !important;
  height: 10px !important;
}
.slickCarousel .slick-dots li:before,
.slickCarousel .slick-dots li:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickCarousel .slick-dots li:before {
  z-index: -1;
  opacity: 0;
  background-image: -moz-linear-gradient(0deg, #026f85 0%, #058da9 100%);
  background-image: -webkit-linear-gradient(0deg, #026f85 0%, #058da9 100%);
  background-image: -ms-linear-gradient(0deg, #026f85 0%, #058da9 100%);
  background-image: linear-gradient(0deg, #026f85 0%, #058da9 100%);
}
.slickCarousel .slick-dots li:after {
  z-index: -2;
  background-image: -moz-linear-gradient(60deg, #e3e3e3 16%, #DBD8D8 100%);
  background-image: -webkit-linear-gradient(60deg, #e3e3e3 16%, #DBD8D8 100%);
  background-image: -ms-linear-gradient(60deg, #e3e3e3 16%, #DBD8D8 100%);
  background-image: linear-gradient(60deg, #e3e3e3 16%, #DBD8D8 100%);
}
.slickCarousel .slick-dots li.slick-active {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.slickCarousel .slick-dots li.slick-active:before {
  opacity: 1;
}
.imageGallery {
  margin-top: 10px;
}
.imageGallery .views-row {
  width: 100%;
}
.imageGallery .field-slideshow-wrapper .field-slideshow .field-slideshow-slide a,
.imageGallery .imageGallery .field-slideshow .field-slideshow-slide a {
  display: block;
  position: relative;
  z-index: 4;
}
.imageGallery .field-slideshow-wrapper .field-slideshow .field-slideshow-slide a img,
.imageGallery .imageGallery .field-slideshow .field-slideshow-slide a img {
  width: 100% !important;
  height: auto !important;
  position: relative;
  z-index: 1;
}
.imageGallery .field-slideshow-wrapper .field-slideshow .field-slideshow-slide a:before,
.imageGallery .imageGallery .field-slideshow .field-slideshow-slide a:before {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-size: 40px;
  opacity: 0;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(-50%) translateY(-50%);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
.imageGallery .field-slideshow-wrapper .field-slideshow .field-slideshow-slide a:after,
.imageGallery .imageGallery .field-slideshow .field-slideshow-slide a:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: -moz-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: -webkit-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: -ms-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: linear-gradient(0deg, #111111 0%, #232323 100%);
  z-index: 3;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  opacity: 0;
}
.imageGallery .field-slideshow-wrapper .field-slideshow .field-slideshow-slide a:hover:before,
.imageGallery .imageGallery .field-slideshow .field-slideshow-slide a:hover:before {
  font-size: 60px;
  opacity: 1;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.imageGallery .field-slideshow-wrapper .field-slideshow .field-slideshow-slide a:hover:after,
.imageGallery .imageGallery .field-slideshow .field-slideshow-slide a:hover:after {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  opacity: 0.4;
}
.imageGallery .field-slideshow-wrapper .field-slideshow .field-slideshow-slide .field-slideshow-caption .field-slideshow-caption-text,
.imageGallery .imageGallery .field-slideshow .field-slideshow-slide .field-slideshow-caption .field-slideshow-caption-text {
  color: #058da9;
  font-size: 18px;
  line-height: 100%;
}
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager,
.imageGallery .imageGallery .item-list .field-slideshow-pager,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager,
.imageGallery .field-slideshow-wrapper .item-list .field-items,
.imageGallery .imageGallery .item-list .field-items,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items,
.imageGallery .imageGallery .field-type-image .field-items {
  display: box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  background-image: -moz-linear-gradient(0deg, #026f85 0%, #058da9 100%);
  background-image: -webkit-linear-gradient(0deg, #026f85 0%, #058da9 100%);
  background-image: -ms-linear-gradient(0deg, #026f85 0%, #058da9 100%);
  background-image: linear-gradient(0deg, #026f85 0%, #058da9 100%);
  padding: 5px;
  width: 100%;
}
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager li,
.imageGallery .imageGallery .item-list .field-slideshow-pager li,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager li,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager li,
.imageGallery .field-slideshow-wrapper .item-list .field-items li,
.imageGallery .imageGallery .item-list .field-items li,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items li,
.imageGallery .imageGallery .field-type-image .field-items li,
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager .field-item,
.imageGallery .imageGallery .item-list .field-slideshow-pager .field-item,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager .field-item,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager .field-item,
.imageGallery .field-slideshow-wrapper .item-list .field-items .field-item,
.imageGallery .imageGallery .item-list .field-items .field-item,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items .field-item,
.imageGallery .imageGallery .field-type-image .field-items .field-item {
  margin-bottom: 0;
  line-height: 0;
  font-size: 0;
  float: none;
  display: inline-block;
  padding: 5px 2.5px;
  width: 100% / 4;
  overflow: hidden;
  position: relative;
}
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager li a,
.imageGallery .imageGallery .item-list .field-slideshow-pager li a,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager li a,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager li a,
.imageGallery .field-slideshow-wrapper .item-list .field-items li a,
.imageGallery .imageGallery .item-list .field-items li a,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items li a,
.imageGallery .imageGallery .field-type-image .field-items li a,
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager .field-item a,
.imageGallery .imageGallery .item-list .field-slideshow-pager .field-item a,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager .field-item a,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager .field-item a,
.imageGallery .field-slideshow-wrapper .item-list .field-items .field-item a,
.imageGallery .imageGallery .item-list .field-items .field-item a,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items .field-item a,
.imageGallery .imageGallery .field-type-image .field-items .field-item a {
  margin-bottom: 0;
  line-height: 0;
  font-size: 0;
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager li a:hover:before,
.imageGallery .imageGallery .item-list .field-slideshow-pager li a:hover:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager li a:hover:before,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager li a:hover:before,
.imageGallery .field-slideshow-wrapper .item-list .field-items li a:hover:before,
.imageGallery .imageGallery .item-list .field-items li a:hover:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items li a:hover:before,
.imageGallery .imageGallery .field-type-image .field-items li a:hover:before,
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager .field-item a:hover:before,
.imageGallery .imageGallery .item-list .field-slideshow-pager .field-item a:hover:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager .field-item a:hover:before,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager .field-item a:hover:before,
.imageGallery .field-slideshow-wrapper .item-list .field-items .field-item a:hover:before,
.imageGallery .imageGallery .item-list .field-items .field-item a:hover:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items .field-item a:hover:before,
.imageGallery .imageGallery .field-type-image .field-items .field-item a:hover:before {
  font-size: 30px;
  opacity: 1;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager li a:hover:after,
.imageGallery .imageGallery .item-list .field-slideshow-pager li a:hover:after,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager li a:hover:after,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager li a:hover:after,
.imageGallery .field-slideshow-wrapper .item-list .field-items li a:hover:after,
.imageGallery .imageGallery .item-list .field-items li a:hover:after,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items li a:hover:after,
.imageGallery .imageGallery .field-type-image .field-items li a:hover:after,
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager .field-item a:hover:after,
.imageGallery .imageGallery .item-list .field-slideshow-pager .field-item a:hover:after,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager .field-item a:hover:after,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager .field-item a:hover:after,
.imageGallery .field-slideshow-wrapper .item-list .field-items .field-item a:hover:after,
.imageGallery .imageGallery .item-list .field-items .field-item a:hover:after,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items .field-item a:hover:after,
.imageGallery .imageGallery .field-type-image .field-items .field-item a:hover:after {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  opacity: 0.4;
}
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager li a:before,
.imageGallery .imageGallery .item-list .field-slideshow-pager li a:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager li a:before,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager li a:before,
.imageGallery .field-slideshow-wrapper .item-list .field-items li a:before,
.imageGallery .imageGallery .item-list .field-items li a:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items li a:before,
.imageGallery .imageGallery .field-type-image .field-items li a:before,
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager .field-item a:before,
.imageGallery .imageGallery .item-list .field-slideshow-pager .field-item a:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager .field-item a:before,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager .field-item a:before,
.imageGallery .field-slideshow-wrapper .item-list .field-items .field-item a:before,
.imageGallery .imageGallery .item-list .field-items .field-item a:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items .field-item a:before,
.imageGallery .imageGallery .field-type-image .field-items .field-item a:before {
  color: #fff;
  position: absolute;
  margin-top: -4px;
  margin-left: -15px;
  width: 30px;
  height: 30px;
  line-height: 0;
  top: 50%;
  left: 50%;
  z-index: 5;
  font-size: 1px;
  opacity: 0;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager li a:after,
.imageGallery .imageGallery .item-list .field-slideshow-pager li a:after,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager li a:after,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager li a:after,
.imageGallery .field-slideshow-wrapper .item-list .field-items li a:after,
.imageGallery .imageGallery .item-list .field-items li a:after,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items li a:after,
.imageGallery .imageGallery .field-type-image .field-items li a:after,
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager .field-item a:after,
.imageGallery .imageGallery .item-list .field-slideshow-pager .field-item a:after,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager .field-item a:after,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager .field-item a:after,
.imageGallery .field-slideshow-wrapper .item-list .field-items .field-item a:after,
.imageGallery .imageGallery .item-list .field-items .field-item a:after,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items .field-item a:after,
.imageGallery .imageGallery .field-type-image .field-items .field-item a:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: -moz-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: -webkit-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: -ms-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: linear-gradient(0deg, #111111 0%, #232323 100%);
  z-index: 3;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  opacity: 0;
}
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager li a img,
.imageGallery .imageGallery .item-list .field-slideshow-pager li a img,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager li a img,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager li a img,
.imageGallery .field-slideshow-wrapper .item-list .field-items li a img,
.imageGallery .imageGallery .item-list .field-items li a img,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items li a img,
.imageGallery .imageGallery .field-type-image .field-items li a img,
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager .field-item a img,
.imageGallery .imageGallery .item-list .field-slideshow-pager .field-item a img,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager .field-item a img,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager .field-item a img,
.imageGallery .field-slideshow-wrapper .item-list .field-items .field-item a img,
.imageGallery .imageGallery .item-list .field-items .field-item a img,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items .field-item a img,
.imageGallery .imageGallery .field-type-image .field-items .field-item a img {
  position: relative !important;
  z-index: 1 !important;
}
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager li.activeSlide a:before,
.imageGallery .imageGallery .item-list .field-slideshow-pager li.activeSlide a:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager li.activeSlide a:before,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager li.activeSlide a:before,
.imageGallery .field-slideshow-wrapper .item-list .field-items li.activeSlide a:before,
.imageGallery .imageGallery .item-list .field-items li.activeSlide a:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items li.activeSlide a:before,
.imageGallery .imageGallery .field-type-image .field-items li.activeSlide a:before,
.imageGallery .field-slideshow-wrapper .item-list .field-slideshow-pager .field-item.activeSlide a:before,
.imageGallery .imageGallery .item-list .field-slideshow-pager .field-item.activeSlide a:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-slideshow-pager .field-item.activeSlide a:before,
.imageGallery .imageGallery .field-type-image .field-slideshow-pager .field-item.activeSlide a:before,
.imageGallery .field-slideshow-wrapper .item-list .field-items .field-item.activeSlide a:before,
.imageGallery .imageGallery .item-list .field-items .field-item.activeSlide a:before,
.imageGallery .field-slideshow-wrapper .field-type-image .field-items .field-item.activeSlide a:before,
.imageGallery .imageGallery .field-type-image .field-items .field-item.activeSlide a:before {
  opacity: 1;
}
.donthyphenate {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  hyphens: none;
}
.inlineVideo {
  width: 100%;
  overflow: hidden;
}
.inlineVideo video {
  position: relative;
  cursor: pointer;
  z-index: 1;
}
.labelButton {
  background-image: -moz-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: -webkit-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: -ms-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: linear-gradient(0deg, #111111 0%, #232323 100%);
  color: #fff;
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 100%;
  padding: 2.5px 5px;
  border-right: 2px solid #000;
  border-bottom: 3px solid #000;
  display: inline-block;
}
.labelButtonDark {
  background-image: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 55%, rgba(255, 255, 255, 0.95) 100%);
  background-image: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 55%, rgba(255, 255, 255, 0.95) 100%);
  background-image: -ms-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 55%, rgba(255, 255, 255, 0.95) 100%);
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 55%, rgba(255, 255, 255, 0.95) 100%);
  color: #fff;
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 100%;
  padding: 2.5px 5px;
  border-right: 2px solid #4b4b4b;
  border-bottom: 3px solid #4b4b4b;
  display: inline-block;
}
.fullBackgroundBlock .block-content {
  overflow: hidden;
  position: relative;
}
.fullBackgroundBlock .block-content .block-title {
  margin-bottom: 0;
  line-height: 0;
  font-size: 0;
}
.fullBackgroundBlock .block-content .block-title a {
  position: absolute;
  z-index: 3;
  left: 20px;
  bottom: 36px;
  background-image: -moz-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: -webkit-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: -ms-linear-gradient(0deg, #111111 0%, #232323 100%);
  background-image: linear-gradient(0deg, #111111 0%, #232323 100%);
  color: #fff;
  font-family: 'Roboto-Bold', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 100%;
  padding: 2.5px 5px;
  border-right: 2px solid #000;
  border-bottom: 3px solid #000;
  display: inline-block;
}
.fullBackgroundBlock .block-content .content:after {
  content: " ";
  display: block;
  background: #026f85;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  mix-blend-mode: color;
  -webkit-box-shadow: inset 0px 0px 119px 25px #000000;
  -moz-box-shadow: inset 0px 0px 119px 25px #000000;
  box-shadow: inset 0px 0px 119px 25px #000000;
}
.fullBackgroundBlock .block-content .content > p {
  margin-bottom: 0;
  line-height: 0;
  font-size: 0;
}
.fullBackgroundBlock .block-content .content > p img {
  width: 100% !important;
  height: auto !important;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  -webkit-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
  -moz-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
  -o-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
  -ms-transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
  transform: rotate(0deg) scale(1) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: 1;
}
.fullBackgroundBlock .block-content .content h1,
.fullBackgroundBlock .block-content .content h2 {
  position: absolute;
  z-index: 3;
  left: 20px;
  bottom: 0;
  color: #fff;
  text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.95);
  font-size: 30px;
}
@media screen and (max-width: 1275px) {
  .fullBackgroundBlock .block-content .content h1,
  .fullBackgroundBlock .block-content .content h2 {
    font-size: 26px;
  }
}
@media screen and (max-width: 1135px) {
  .fullBackgroundBlock .block-content .content h1,
  .fullBackgroundBlock .block-content .content h2 {
    font-size: 22px;
  }
}
.fullBackgroundBlock .block-content .block-link a {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  z-index: 4;
  opacity: 0;
  padding: 0;
}
.fullBackgroundBlock .block-content .block-link a:after,
.fullBackgroundBlock .block-content .block-link a:before {
  display: none;
}
.fullBackgroundBlock .block-content:hover .content > p img {
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  -webkit-transform: rotate(0deg) scale(1.2) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
  -moz-transform: rotate(0deg) scale(1.2) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
  -o-transform: rotate(0deg) scale(1.2) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
  -ms-transform: rotate(0deg) scale(1.2) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
  transform: rotate(0deg) scale(1.2) skewX(0deg) skewX(0deg) translateX(0px) translateY(0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
.fullBackgroundBlock .block-content:hover .content:after {
  opacity: 0.4;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.fadeOut {
  opacity: 0;
}
.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}
.active {
  display: block;
}
.inactive {
  display: none;
}
.visible {
  display: block;
}
.invisible {
  display: none;
}
.hyphenate {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -o-hyphens: auto;
  hyphens: auto;
}
.block-link a,
.block-link a a {
  display: inline-block;
  position: relative;
  z-index: 3;
  color: #82BB51;
  background-color: #000;
  font-size: 18px;
  padding: 10px 20px;
  border: 1px solid #82BB51;
  border-radius: 10px;
  line-height: 100%;
  cursor: pointer;
  font-family: 'Roboto-Black';
  font-weight: 700;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}
.block-link a:hover,
.block-link a a:hover {
  color: #fff;
  border: 1px solid #fff;
  -webkit-box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
  -moz-box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
  box-shadow: 0px 0px 75px 0px rgba(112, 80, 28, 0.3);
}
.block-link a.big,
.block-link a.big a {
  font-size: 20px;
  padding: 7.14285714px 10px;
}
